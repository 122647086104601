import * as React from "react";
import { Link } from "gatsby";
import IDK from "../images/memoji/idk.png";

const titleStyles = {
  display: "flex",
  flexDirection: "row",
  height: "100vh",
  maxWidth: "80vw",
  alignItems: "center"
}

const textStyle = {
  color: "white",
  fontFamily: "Manrope",
}

const bigText = {
  ...textStyle,
  fontSize: "90px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "80%",
  marginBottom: "20px"
}

const smallText = {
  ...textStyle,
  fontStyle: "normal",
  fontWeight: "200",
  fontSize: "22px",
  lineHeight: "25px",
  paddingBottom: "20px"
}

const textAlign = {
  marginLeft: "55px",
  zIndex: "10",
  
}

const imageStyle = {
  marginRight: "55px",
  zIndex: "10",
  maxHeight: "250px"
}


const Title = () => {
  return (
    <div style={titleStyles} id="title">
      <img src={IDK} alt="I don't know!" style={imageStyle}/>      
      
      <div style={textAlign}>
        <div style={bigText}>Not Found</div>
        <div style={smallText}>Sorry! We couldn’t find what you were looking for.</div>
        <Link style={textStyle} to="/">Go home</Link>
      </div>
    </div>
  )
}

export default Title;