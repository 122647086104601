import * as React from "react";
import Error from "../components/404";
import "../global.css";

const pageStyles = {
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  background: "linear-gradient(180deg, #1B1B35 84%, #422157 100%)",
  overflowX: "hidden",
  minHeight: "100vh",
  padding: "0px",
  display: "flex",
  flexDirection: "column",
}

const column = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <div style={column}>
        <Error/>
      </div>
    </main>
  )
}

export default NotFoundPage;
